import React from 'react';
import banner from "../img/proyecto-maluba-280623.jpg";
import footer from "../img/footer.jpg";
import "../styles.css";

const Home = () => (
  <div className="container-fluid">
			<div className="row">
				<div className="col-12">
					<img src={banner} className="banner" alt="Jornada de Investigación de materias primas y subproductos" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="alert alert-warning centrado" role="alert">
						<h5>Nuevos enfoques agroindustriales para las materias primas y subproductos de la industria de la cerveza: Malta, Lúpulo y Bagazo</h5>
						<h6>Jornada de Investigación de materias primas y subproductos</h6>
						<h7><strong>Fecha y horario:</strong> 28 de junio de 2023, de 10:00 a 14:00</h7><br></br>
						<h7><strong>Lugar:</strong> Parque Científico - Campus Universitario La Yutera (Palencia)</h7>
						<hr></hr>
						<strong>Objetivos del proyecto:</strong>
						<p>El objeto de esta jornada es dar a conocer el proyecto MALUBA sobre nuevos enfoques agroindustriales para las materias primas y subproductos de la industria de la cerveza: Malta, Lúpulo y Bagazo, con el objetivo de optimizar estos productos e incorporación en productos vinculados a la panificación y desarrollo de nuevas cervezas basadas en las tendencias del mercado.</p>
						<p>Este proyecto ha sido financiado por ICE a través de la línea de Proyectos de I+D en colaboración efectiva entre Centros Tecnológicos y Empresas.</p>
					</div>
				</div>
			</div>
			<div className="card text-black bg-warning w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title"><i class="fas fa-hand-point-right"></i>&nbsp;&nbsp;ASISTENCIA GRATUITA&nbsp;&nbsp;<i class="fas fa-hand-point-left"></i></h5>
					<a href="#form"><p><strong>RESERVA TU PLAZA</strong></p>
        			</a>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="card border-secondary w-100 caracteristicas izquierda">
					<div className="card-body">
						<h5 className="card-title centrado">Agenda:</h5>
						<li><strong>09:45 Recepción y entrega de documentación</strong><br></br>
						</li>
						<li><strong>10:00 Presentación: Ayudas de ICE y Programa Centr@tec3</strong><br></br>
						<i>D. Carmelo Seco.  Director Delegación Territorial ICE - Palencia</i><br></br>
						<i>Dª. María del Mar de Blas, OTRI Itagra CT</i><br></br>
						<i>Dª. Eva de la Gala, Adjunta Dirección  CETECE</i><br></br>
						</li>
						<li><strong>10:15 Estudio y evaluación integral de los ingredientes para la elaboración de la cerveza palentina: lúpulo, cebada y otros cereales.</strong><br></br>
						Dª. Susana Luis, Área de I+D+i Itagra CT
						</li>
						<li><strong>11:00 Aplicaciones de la Malta, el Lúpulo y el Bagazo en productos de panificación.</strong><br></br>
						Dª. Ana Garcinuño Prados, Directora I+D+I CETECE
						</li>
						<li><strong>11:45 Café networking</strong></li>
						<li><strong>12:15 Experiencia piloto de la obtención de Malta</strong><br></br>
						D. Javier Canseco, fundador de Cereales Abonos y Piensos Canseco
						</li>
						<li><strong>12:30 Microcervecería artesanal en el medio rural</strong><br></br>
						Dª. María Fernanda Jiménez, cofundadora de Cervezas Yesta Agrobeer
						</li>
						<li><strong>13:00 Cata de cervezas desarrolladas en el proyecto MALUBA</strong><br></br>
						Dª. María Fernanda Jiménez, cofundadora de Cervezas Yesta Agrobeer<br></br>
						<strong>Cata de panes desarrollados en el proyecto MALUBA</strong><br></br>
						Dª. Asunción Alonso Gaite, Responsable Análisis Sensorial CETECE
						</li>
						<li><strong>14:00 Cierre de la Jornada</strong>
						</li>
					</div>
					</div>
				</div>
			</div>
			<div className="card text-white bg-secondary border-secondary w-100 caracteristicas">
				<div className="card-body">
					<h5 className="card-title">Fecha y horario:</h5>
					<p className="card-text-left"> 28 de junio de 2023, de 10:00 a 14:00</p>
					<p className="card-text-left"> Parque Científico - Campus Universitario La Yutera (Palencia)</p>
				</div>
			</div>
			<div className="row inscripcion" id="formulario">
				<div className="col-12">
					<div className="alert alert-light text-center" role="alert" id="form">
						<h3>Formulario de inscripción</h3>
						<hr />
						<iframe src="//cursos.itagraformacion.com/form/686" width="95%" height="900px" title='formulario inscripción'><p>Tu navegador no admite iframes.</p></iframe>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<img src={footer} className="banner" alt="Jornada de Investigación de materias primas y subproductos" />
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					Más información <i class="fas fa-phone-square-alt"></i>
					&nbsp;979 10 83 03&nbsp;
					<i class="fas fa-paper-plane"></i>&nbsp;info@itagra.com
				</div>
			</div>
			<div className="row">
				<div className="col-12 info">
					<a href="https://www.itagra.com">Itagra CT</a>
				</div>
			</div>
		</div>
)

export default Home